import React from 'react'
 
function Parallax() {
  return (
     <>
       <section className="animated-background">
        <div id="stars1" />
        <div id="stars2" />
        <div id="stars3" />
      </section>
      </>
  )
}

export default Parallax