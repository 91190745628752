import React from 'react'
import Parallax from '../Parallax/Parallax'
import './css/style.css'

function Notfound() {
  return (
    <div id="notfound">
      <Parallax/>
  <div className="notfound">
    <div className="notfound-404">
      <h1>Oops!</h1>
      <h2>404 - The Page can't be found</h2>
    </div>
    <a href="/">Go TO Homepage</a>
  </div>
</div>

  )
}

export default Notfound