import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner'
import Footer from "../footer/Footer";


function Main() {

  
const [items, setItems] = useState(null);
const [loading, setLoading]=useState(true)

  useEffect(() => {
      const fetchItems = async () => {
        const result = await axios.get(`https://link.aymenghnia.dev/mockibody`)
        console.log(result)
        setItems(result.data)
        setLoading(false)
      }
  
      fetchItems()
    },[]);


  return loading ? <div style={{paddingTop:"50px",paddingBottom:"50px",alignItems:"center",justifyContent:"center",height:"100vh"}}><InfinitySpin 
  width='200'
  color="#ffffff"
/>

<div id="hashtag" style={{paddingTop:"15px",paddingBottom:"50px"}}>
   #Sorry Failed to load Data !  
</div>

</div> 
:
(
   <> 
    <div id="links">

    {items.map((el,index)=>
    <a key={index} className="link" href={el.link} target="_blank">
      <i className={el.icon}>&nbsp;&nbsp;</i>{el.title}
     </a>
        
)}

    {/* <a className="link" href="https://www.linkedin.com/in/johnggli" target="_blank">
      <i className="fab fa-linkedin">&nbsp;</i>Linkedin
    </a>
    <a className="link" href="https://www.facebook.com/johnggli" target="_blank">
      <i className="fab fa-facebook">&nbsp;</i>Facebook
    </a>
    <a className="link" href="https://www.instagram.com/johnggli" target="_blank">
      <i className="fab fa-instagram">&nbsp;</i>Instagram
    </a>
    <a className="link" href="https://twitter.com/johnggli" target="_blank">
      <i className="fab fa-twitter">&nbsp;</i>Twitter
    </a>
    <a className="link" href="https://myanimelist.net/profile/johnggli" target="_blank">
      <i className="fa fa-play-circle">&nbsp;</i>MyAnimeList
    </a> */}

     
  </div>
  
  <Footer/></>
  
  )
}

export default Main