import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner'

function Header() {
  
  const [itemxs, setItemxs] = useState(null);
  const [loading, setLoading]=useState(true)

    useEffect(() => {
        const fetchItemxs = async () => {
          const resultx = await axios.get(`https://link.aymenghnia.dev/mockiHead`)
          console.log(resultx)
          setItemxs(resultx.data)
          setLoading(false)
        }
    
        fetchItemxs()
      },[]);





  return loading ? <div style={{paddingTop:"50px",paddingBottom:"50px",alignItems:"center",justifyContent:"center",height:"100vh"}}><InfinitySpin 
  width='200'
  color="#ffffff"
/>

<div id="hashtag" style={{paddingTop:"15px",paddingBottom:"50px"}}>
   #Sorry Failed to load Data !  
</div>

</div> 
:
(
    <div href={itemxs.link}>
    <a   target="_blank" id="profilePicture">
      <img src={itemxs.imgDay} alt="Aymen Ghnia Picture" />
    </a>
    
    <div id="userName">
      {itemxs.username}
    </div>
    <p style={{color:"white"}}>{itemxs.desc}
</p>
  </div>
  )
}

export default Header