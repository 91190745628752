import React from 'react'

function Footer() {
  return (
<div id="hashtag">
Made with ❤ in My bed ! #BeHappy ❤ | Aymen Ghnia
</div>

  )
}

export default Footer