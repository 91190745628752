import logo from './logo.svg';
import './App.css';
import Day from './components/Day/Day';
import {Routes, Route ,Navigate} from "react-router-dom";
import Notfound from './components/Day/404/Notfound';


function App() {
  return (
    <div className="App">
     
     <Routes>
               <Route exact path="/" element={<Day />} />
            <Route path="/404" element={<Notfound />} />
               <Route path="*" element={<Notfound />} />
            </Routes> 
    </div>
  );
}

export default App;
