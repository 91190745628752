import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner'
import Swal from 'sweetalert2'

import Main from './main/Main';
import اHeader from './header/Header';
import Footer from './footer/Footer';
import Parallax from './Parallax/Parallax';


import './Day.css'

// Swal.fire({
//   title: 'Hey there!',
//   text: 'I just couldn\'t resist making some improvements to my portfolio. Check out the shiny new version or continue enjoying this one. Your choice!',
//   icon: 'info',
//   showCancelButton:true,
//    confirmButtonColor: '#808080',
//   cancelButtonColor: '#d33',
//   cancelButtonText:'Go to New Version',
//   confirmButtonText: 'Continue Here'
// }).then((result) => {
//   if (result.isConfirmed) {
//     window.location.href = 'portfolio.aymenghnia.dev';
//   }
// })
 
 

function Day() {

      
const [items, setItems] = useState(null);
const [loading, setLoading]=useState(true)
    useEffect(() => {
        const fetchItems = async () => {
          const result = await axios.get(`https://link.aymenghnia.dev/mockibody`)
          // console.log(result)
          console.log(result)
          setItems(result.data)
          setLoading(false)
        }
    
        fetchItems()
      },[]);
  
  
    return loading ? <div style={{display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"center",height:"100vh"}}>
        
        
        <InfinitySpin 
    width='200'
    color="#ffffff"
  />

  
  
  <div id="hashtag" style={{paddingTop:"15px",paddingBottom:"50px"}}>
     #Sorry Failed to Links !  
  </div>
  
  </div> 
  :
  (
    <div>
      <Parallax />
      <اHeader />
      <Main />
      {/* <Footer/> */}
    </div>
  );
}

export default Day;
